import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import FAQ from 'components/FAQ';

export default function FaqPage({ pageContext: { common, faqs, links, navigation, tag } }) {

  return (
    <>
    <Helmet>
      <meta property='og:image' content='/seo/preview.jpg' />
      <meta property='og:image:width' content={820} />
      <meta property='og:image:height' content={540} />
    </Helmet>
    <Layout
      isHeaderTransparent
      links={links}
      navigation={navigation}
      tag={tag}
      metaTitle='FAQ | Reach Blockchain Development Platform'
      metaDescription='Find answers to the most frequently asked questions surrounding our blockchain development platform.'
    >
      <FAQ
        title={common.faq_title_text}
        learnMore={common.learn_more_about_it_on_text}
        faqs={faqs}
        page="faq"
      />
    </Layout>
    </>
  )
}
